import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Domain from "./components/Courses/Domain";
import SubtopicPage from "./components/Courses/SubtopicPage";
import ForgotPassword from "./components/Login/ForgotPassword";
import ResetPassword from "./components/Login/ResetPassword";
import PackPage from "./components/Packs/PacksPage";
import TestPage from "./components/Test/TestPage";
import MainLayout from "./components/dashboardNew/DashBoardLayout";
import AccountSettings from "./components/dashboardNew/pages/AccountSetting";
import Nopage from "./components/nopage/Nopage";
import PracticeTestMain from "./components/practicetest/PracticeTestMain";
import PrepModules from "./components/prepmodules/PrepModules";
import AboutPage from "./pages/Aboutpage";
import Coursespage from "./pages/Coursespage";
import Homepage from "./pages/Homepage";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Syllabus from "./pages/Syllabus";
import University from "./pages/UniversityPage";
import PrivateRoutes from "./utils/PrivateRoutes";
// import DashBoardNew from "./components/dashboardNew/pages/Dashboard";
import Cards from "./components/dashboardNew/Cards/Cards";
import UserPlans from "./components/dashboardNew/pages/UserPlans";
import BoughtPack from "./pages/BoughtPack";
// import PriceTable from "./components/Packs/PriceCard";
import Blog from "./pages/Blog";

import { useAuth } from "./utils/context";

import BlogStructure from "./components/BlogCard/Structure";
import CoursesNew from "./components/Courses/CoursesNew";
// import Modal from "./components/home/Modal";
import { MathJaxContext } from "better-react-mathjax";
import CancellationRefundPolicy from "./pages/Cancellation";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/Privacy";
import ShippingPolicy from "./pages/Shipping";
import TermsAndConditions from "./pages/TermsAndConditions";

import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from "@vercel/analytics/react";
import 'primeicons/primeicons.css';
        

const config = {
  loader: { load: ["input/tex", "output/chtml"] },
  tex: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
  },
};

const App = () => {
  const [auth] = useAuth();
  const selectedSubjects = auth?.user?.selectedSubjects;
  // const [showFirstModal, setShowFirstModal] = useState(false);
  // const [showSecondModal, setShowSecondModal] = useState(false);

  // useEffect(() => {
  //   const isExcludedPath =
  //     ["/login", "/signup", "/forgot-password", "/purchase"].includes(
  //       window.location.pathname
  //     ) || window.location.pathname.startsWith("/reset_password/");

  //   if (!isExcludedPath) {
  //     const firstModalTimer = setTimeout(() => {
  //       setShowFirstModal(true);
  //     }, 2 * 1000);

  //     const secondModalTimer = setTimeout(() => {
  //       setShowSecondModal(true);
  //     }, 5 * 60 * 1000);

  //     return () => {
  //       clearTimeout(firstModalTimer);
  //       clearTimeout(secondModalTimer);
  //     };
  //   }
  // }, []);

  // const closeModal = () => {
  //   setShowFirstModal(false);
  //   setShowSecondModal(false);
  // };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Current Page",
    });
  }, []);

  return (
    <>
      <MathJaxContext version={3} config={config}>
        <Routes>
          <Route
            path="/login"
            element={!auth?.user ? <Login /> : <Homepage />}
          />
          <Route
            path="/signup"
            element={!auth?.user ? <Signup /> : <Homepage />}
          />
          <Route
            path="/forgot-password"
            element={!auth?.user ? <ForgotPassword /> : <Homepage />}
          />
          <Route
            path="/profile"
            element={
              auth?.user ? <MainLayout /> : <Navigate to="/login" replace />
            }
          >
            <Route index element={<AccountSettings />} />
            <Route path="progress" element={<Cards />} />
            <Route path="plans" element={<UserPlans />} />
            {/* <Route path="plans" element={<PriceTable hidden="hidden" />} /> */}
          </Route>
          <Route
            path="/reset_password/:id/:token/:expire"
            element={!auth?.user ? <ResetPassword /> : <Homepage />}
          />
          <Route
            path="/courses"
            element={!auth?.user ? <Coursespage /> : <CoursesNew />}
          />
          <Route path="/Domain" element={<Domain />} />
          <Route
            path="/"
            element={
              selectedSubjects && selectedSubjects.length > 0 ? (
                <BoughtPack />
              ) : (
                <Homepage />
              )
            }
          />{" "}
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route
            path="cancellation-and-refund"
            element={<CancellationRefundPolicy />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blogs/:topic" element={<BlogStructure />} />
          <Route path="/syllabus" element={<Syllabus />} />
          <Route path="/purchase" element={<PackPage />} />
          <Route path="/university" element={<University />} />
          <Route path="/courses/:topic" element={<TestPage />} />
          <Route path="/courses/:topic/:testCategory" element={<TestPage />} />
          <Route path="/courses/domain/:heading" element={<SubtopicPage />} />
          <Route
            path="/courses/prep/:subject/:topic/:subTopic"
            element={<PrepModules />}
          />
          <Route
            path="/courses/prep/:topic/:subTopic"
            element={<PrepModules />}
          />
          <Route
            path="/courses/practice/:topic/:subTopic"
            element={<PracticeTestMain />}
          />
          <Route element={<PrivateRoutes />}>
            <Route
              path="/courses/practice/:subTopic"
              element={<PracticeTestMain />}
            />
          </Route>
          <Route path="*" element={<Nopage />} />
        </Routes>
        {/* {showFirstModal && (
        <Modal
          isOpen={showFirstModal}
          onClose={closeModal}
          imageSrc={require("./assets/images/banner02.jpeg")}
        />
      )}
      {!showFirstModal && showSecondModal && (
        <Modal
          isOpen={showSecondModal}
          onClose={closeModal}
          imageSrc={require("./assets/images/Banner01.png")}
        />
      )} */}
      </MathJaxContext>
      <Analytics />
      <SpeedInsights />
    </>
  );
};

export default App;
