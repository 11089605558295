import React, { useRef } from 'react';
import { TieredMenu } from 'primereact/tieredmenu';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/fluent-light/theme.css';
import 'primereact/resources/primereact.min.css';
import './notifications.css';
import SubMenu from './SubMenu';

export default function  Notification({isMobile}) {
    const menu = useRef(null);

    const menuItems = [
        {
            label: 'ENGINEERING',
            items: [
                {
                    template: () => (
                        <SubMenu
                            examName="JEE Mains"
                            logoSrc={require("../../assets/notifications-logo/nta.webp")}
                            logoWidth="w-12"
                            logoHeight="h-12"
                            applyStatus="over"
                            applyLink="#"
                            officialLink="https://jeemain.nta.nic.in/"
                            calendarDate="Closed"
                        />
                    ),
                },
                {
                    template: () => (
                        <SubMenu
                            examName="BITSAT"
                            logoSrc={require("../../assets/notifications-logo/bits.gif")}
                            logoWidth="w-20"
                            logoHeight="h-12"
                            applyStatus="over"
                            applyLink="#"
                            officialLink="https://www.bitsadmission.com/"
                            calendarDate="Closed"
                        />
                    ),
                },
                {
                    template: () => (
                        <SubMenu
                            examName="VITEE"
                            logoSrc={require("../../assets/notifications-logo/viteee.png")}
                            logoWidth="w-12"
                            logoHeight="h-12"
                            applyStatus="ongoing"
                            applyLink="https://viteee.vit.ac.in/"
                            officialLink="https://viteee.vit.ac.in/"
                            calendarDate="31 MAR"
                        />
                    ),
                },
                {
                    template: () => (
                        <SubMenu
                            examName="SRMJEE"
                            logoSrc={require("../../assets/notifications-logo/srm.png")}
                            logoWidth="w-12"
                            logoHeight="h-12"
                            applyStatus="ongoing"
                            applyLink="https://srmjeee.srmap.edu.in/"
                            officialLink="https://srmjeee.srmap.edu.in/"
                            calendarDate="16 APR"
                        />
                    ),
                },
                {
                    template: () => (
                        <SubMenu
                            examName="COMEDK"
                            logoSrc={require("../../assets/notifications-logo/comedk.jpg")}
                            logoWidth="w-12"
                            logoHeight="h-12"
                            applyStatus="upcoming"
                            applyLink="https://cdn.digialm.com//EForms/configuredHtml/1022/87392/login.html"
                            officialLink="https://www.comedk.org/"
                            calendarDate="JAN 25"
                        />
                    ),
                },
                {
                    template: () => (
                        <SubMenu
                            examName="NATA"
                            logoSrc={require("../../assets/notifications-logo/nata.png")}
                            logoWidth="w-12"
                            logoHeight="h-12"
                            applyStatus="upcoming"
                            applyLink="https://www.nata.in/"
                            officialLink="https://www.nata.in/"
                            calendarDate="JAN 25"
                        />
                    ),
                },
            ]
        },
        {
            label: 'MEDICINE',
            items: [
                {
                    template: () => (
                        <SubMenu
                            examName="NEET"
                            logoSrc={require("../../assets/notifications-logo/nta.webp")}
                            logoWidth="w-12"
                            logoHeight="h-12"
                            applyStatus="upcoming"
                            applyLink="https://neet.nta.nic.in/"
                            officialLink="https://neet.nta.nic.in/"
                            calendarDate="FEB 25"
                        />
                    ),
                },
            ]
        },
        {
            label: 'LAW',
            items: [
                {
                    template: () => (
                        <SubMenu
                            examName="CLAT"
                            logoSrc={require("../../assets/notifications-logo/clat.jpg")}
                            logoWidth="w-16"
                            logoHeight="h-16"
                            applyStatus="over"
                            applyLink="#"
                            officialLink="https://consortiumofnlus.ac.in/"
                            calendarDate="Closed"
                        />
                    ),
                },
            ]
        },
    ];

    return (
        <div className="custom-tiered-menu">
            <Button
                label={!isMobile ? "Exam Notifications" : null}
                icon="pi pi-bell"
                onClick={(e) => menu.current.toggle(e)}
                className={`animated-bell rounded-full ${isMobile ? "p-button-icon-only" : ""}`}
            />
            <TieredMenu model={menuItems} popup ref={menu} breakpoint="1024px" />
        </div>
    );
}
